var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('custom-alert',{attrs:{"title":"An Error Occurred","message":_vm.error,"show":!!_vm.error,"type":"danger"},on:{"dismiss":function($event){_vm.error = ''}}}),_c('div',[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900 mb-10"},[_vm._v(" Please check box for each section below that applies. ")]),_c('ul',{staticClass:"categories_list"},_vm._l((_vm.myAccordionCategory),function(category,key){return _c('li',{key:key,staticClass:"category_item"},[(key !== 3)?_c('div',{staticClass:"accordion"},[_c('el-checkbox',{ref:category.ref,refInFor:true,attrs:{"value":category.visible,"label":category.name,"name":"type"},on:{"change":function($event){return _vm.handleChangeCheckBox($event, category)}}})],1):_vm._e(),(category.visible || key === 3)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(key === 0),expression:"key === 0"}],staticClass:"py-5"},[_c('div',{staticClass:"pb-3"},[_c('label',{attrs:{"for":""}},[_vm._v("Please indicate action to be taken: ")]),_c('el-radio-group',{on:{"change":function($event){return _vm.$emit('updateChangeRequestData', {
                    fieldKey: 'data.actions.limitAction',
                    value: $event
                  })}},model:{value:(_vm.CoverageLimitsRadioButton),callback:function ($$v) {_vm.CoverageLimitsRadioButton=$$v},expression:"CoverageLimitsRadioButton"}},[_c('el-radio',{attrs:{"label":"add"}},[_vm._v("Add")]),_c('el-radio',{attrs:{"label":"change"}},[_vm._v("Change")]),_c('el-radio',{attrs:{"label":"delete"}},[_vm._v("Delete")])],1)],1),_c('div',[_c('p',{staticStyle:{"padding-bottom":"20px"}},[_vm._v(" "+_vm._s(_vm.descriptionCharactersLeft)+" characters left. ")]),_c('el-input',{ref:"field_limitDescription",refInFor:true,staticClass:"w-full",attrs:{"label":"Limit Description","row":10,"maxlength":_vm.maxCharacterLength,"show-word-limit":"","type":"textarea","required":true,"disabled":_vm.CoverageLimitsRadioButton == ''},on:{"blur":function($event){return _vm.$emit('updateChangeRequestData', {
                    fieldKey: 'data.description.limitDescription',
                    value: $event.target.value
                  })},"input":_vm.handleLimitDescription},model:{value:(_vm.propertyChangeRequestLimitDescription),callback:function ($$v) {_vm.propertyChangeRequestLimitDescription=$$v},expression:"propertyChangeRequestLimitDescription"}})],1)]),(key === 1)?_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"py-5"},[_c('label',{attrs:{"for":""}},[_vm._v("Please indicate action to be taken: ")]),_c('el-radio-group',{on:{"change":function($event){return _vm.$emit('updateChangeRequestData', {
                    fieldKey: 'data.actions.endorsementAction',
                    value: $event
                  })}},model:{value:(_vm.EndorsementsLimitsRadioButton),callback:function ($$v) {_vm.EndorsementsLimitsRadioButton=$$v},expression:"EndorsementsLimitsRadioButton"}},[_c('el-radio',{attrs:{"label":"add"}},[_vm._v("Add")]),_c('el-radio',{attrs:{"label":"change"}},[_vm._v("Change")]),_c('el-radio',{attrs:{"label":"delete"}},[_vm._v("Delete")])],1)],1),_c('div',[_c('p',{staticStyle:{"padding-bottom":"20px"}},[_vm._v(" "+_vm._s(_vm.endorsementDescriptionCharactersLeft)+" characters left. ")]),_c('el-input',{ref:"field_limitDescription",refInFor:true,staticClass:"w-full",attrs:{"label":"Limit Description","row":10,"placeholder":"Limit Description","type":"textarea","required":true,"disabled":_vm.EndorsementsLimitsRadioButton == '',"maxlength":_vm.maxCharacterLength,"show-word-limit":""},on:{"blur":function($event){return _vm.$emit('updateChangeRequestData', {
                    fieldKey: 'data.description.endorsementDescription',
                    value: $event.target.value
                  })},"input":_vm.handleEndorsementLimitDescription},model:{value:(_vm.propertyChangeRequestEndorsementDescription),callback:function ($$v) {_vm.propertyChangeRequestEndorsementDescription=$$v},expression:"propertyChangeRequestEndorsementDescription"}})],1)]):_vm._e(),(key === 2)?_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"mb-10"},[_c('label',{attrs:{"for":""}},[_vm._v("Please indicate action to be taken: ")]),_c('IAButton',{staticStyle:{"padding-right":"20px"},attrs:{"click":_vm.clearSelectedMortgage,"title":"Clear","type":"text"}}),_c('el-radio-group',{on:{"change":_vm.handleMortgageRadioChange},model:{value:(_vm.MortgageRadioButton),callback:function ($$v) {_vm.MortgageRadioButton=$$v},expression:"MortgageRadioButton"}},[_c('el-radio',{attrs:{"label":"add"}},[_vm._v("Add")]),_c('el-radio',{attrs:{"label":"change"}},[_vm._v("Change")]),_c('el-radio',{attrs:{"label":"delete"}},[_vm._v("Delete")])],1)],1),_c('div',[_c('TextField',{ref:"field_mortgage",refInFor:true,staticClass:"w-full",attrs:{"fixedLabels":true,"label":"Mortgage Co","placeholder":"Mortgage Company","disabled":(!_vm.enableMortgageFieldWhenOther &&
                    _vm.selectedMortgage.mortgageName !== '') ||
                    _vm.disableMortgageCompanyName,"required":true,"value":_vm.selectedMortgage.mortgageName == 'OTHER' ||
                  _vm.propertyChangeRequestData.data.mortgage.mortgage === 'OTHER'
                    ? ''
                    : _vm.selectedMortgage.mortgageName ||
                      _vm.propertyChangeRequestData.data.mortgage.mortgage},on:{"blur":function($event){return _vm.$emit('updateChangeRequestData', {
                    fieldKey: 'data.mortgage.mortgage',
                    value: $event
                  })},"input":_vm.handleOnInputChange}})],1),_c('div',[_c('TextField',{ref:"field_address",refInFor:true,staticClass:"w-full",attrs:{"fixedLabels":true,"label":"Address","required":true,"placeholder":"Address","disabled":!_vm.enableMortgageFieldWhenOther &&
                    _vm.selectedMortgage.address !== '',"value":_vm.selectedMortgage.address === 'OTHER' ||
                  _vm.propertyChangeRequestData.data.mortgage.address === 'OTHER'
                    ? ''
                    : _vm.selectedMortgage.address ||
                      _vm.propertyChangeRequestData.data.mortgage.address},on:{"blur":function($event){return _vm.$emit('updateChangeRequestData', {
                    fieldKey: 'data.mortgage.address',
                    value: $event
                  })},"input":_vm.handleOnInputChange}})],1),_c('div',{staticClass:"flex gap-2 justify-between"},[_c('div',{staticClass:"w-1/3"},[_c('TextField',{ref:"field_city",refInFor:true,staticClass:"w-full",attrs:{"fixedLabels":true,"label":"City","placeholder":"City","required":true,"disabled":!_vm.enableMortgageFieldWhenOther &&
                      _vm.selectedMortgage.city !== '',"value":_vm.selectedMortgage.city === 'OTHER' ||
                    _vm.propertyChangeRequestData.data.mortgage.mortgageAddress
                      .city === 'OTHER'
                      ? ''
                      : _vm.selectedMortgage.city ||
                        _vm.propertyChangeRequestData.data.mortgage
                          .mortgageAddress.city},on:{"blur":function($event){return _vm.$emit('updateChangeRequestData', {
                      fieldKey: 'data.mortgage.mortgageAddress.city',
                      value: $event
                    })},"input":_vm.handleOnInputChange}})],1),_c('div',{staticClass:"w-1/3"},[_c('TextField',{ref:"field_state",refInFor:true,staticClass:"w-full",attrs:{"fixedLabels":true,"label":"State","placeholder":"state","required":true,"disabled":!_vm.enableMortgageFieldWhenOther &&
                      _vm.selectedMortgage.state !== '',"value":_vm.selectedMortgage.state === 'OTHER' ||
                    _vm.propertyChangeRequestData.data.mortgage.mortgageAddress
                      .state === 'OTHER'
                      ? ''
                      : _vm.selectedMortgage.state ||
                        _vm.propertyChangeRequestData.data.mortgage
                          .mortgageAddress.state},on:{"blur":function($event){return _vm.$emit('updateChangeRequestData', {
                      fieldKey: 'data.mortgage.mortgageAddress.state',
                      value: $event
                    })},"input":_vm.handleOnInputChange}})],1),_c('div',{staticClass:"w-1/3"},[_c('TextField',{ref:"field_zipCode",refInFor:true,staticClass:"w-full",attrs:{"fixedLabels":true,"label":"Zip","required":true,"placeholder":"Zip","disabled":!_vm.enableMortgageFieldWhenOther &&
                      _vm.selectedMortgage.zipCode !== '',"value":_vm.selectedMortgage.zipCode === 'OTHER' ||
                    _vm.propertyChangeRequestData.data.mortgage.mortgageAddress
                      .zipCode === 'OTHER'
                      ? ''
                      : _vm.selectedMortgage.zipCode ||
                        _vm.propertyChangeRequestData.data.mortgage
                          .mortgageAddress.zipCode},on:{"blur":function($event){return _vm.$emit('updateChangeRequestData', {
                      fieldKey: 'data.mortgage.mortgageAddress.zipCode',
                      value: $event
                    })},"input":_vm.handleOnInputChange}})],1)]),_c('div',[_c('TextField',{ref:"field_loanNumber",refInFor:true,staticClass:"w-full",attrs:{"fixedLabels":true,"label":"Loan Number","placeholder":"Loan Number","required":false,"value":_vm.propertyChangeRequestData.data.mortgage.loanNumber},on:{"blur":function($event){return _vm.$emit('updateChangeRequestData', {
                    fieldKey: 'data.mortgage.loanNumber',
                    value: $event
                  })}}})],1),_c('div',{staticClass:"mb-10"},[_c('label',{attrs:{"for":""}},[_vm._v("Remarks "+_vm._s(_vm.handleCharacterCountDown(_vm.mortgageRemarks))+" characters left.")]),_c('el-input',{attrs:{"type":"textarea","placeholder":"Please input","maxlength":"395","show-word-limit":"","clearable":true},on:{"change":function($event){return _vm.$emit('updateChangeRequestData', {
                    fieldKey: 'data.mortgage.remarks',
                    value: _vm.mortgageRemarks
                  })}},model:{value:(_vm.mortgageRemarks),callback:function ($$v) {_vm.mortgageRemarks=$$v},expression:"mortgageRemarks"}})],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-1/2"},[_vm._m(0,true),_c('el-radio-group',{on:{"change":function($event){return _vm.$emit('updateChangeRequestData', {
                      fieldKey: 'data.mortgage.listed',
                      value: $event
                    })}},model:{value:(_vm.MortgageListRadioButton),callback:function ($$v) {_vm.MortgageListRadioButton=$$v},expression:"MortgageListRadioButton"}},[_c('el-radio',{attrs:{"label":"first"}},[_vm._v("First")]),_c('el-radio',{attrs:{"label":"second"}},[_vm._v("Second")]),_c('el-radio',{attrs:{"label":"third"}},[_vm._v("Third")])],1)],1),_c('div',{staticClass:"w-1/2"},[_vm._m(1,true),_c('el-radio-group',{on:{"change":function($event){return _vm.$emit('updateChangeRequestData', {
                      fieldKey: 'data.mortgage.billed',
                      value: $event
                    })}},model:{value:(_vm.BillMortgageRadioButton),callback:function ($$v) {_vm.BillMortgageRadioButton=$$v},expression:"BillMortgageRadioButton"}},[_c('el-radio',{attrs:{"label":"Yes"}},[_vm._v("Yes")]),_c('el-radio',{attrs:{"label":"No"}},[_vm._v("No")])],1)],1)])]):_vm._e(),(key === 3)?_c('div',{staticClass:"py-5"},[_c('ChangeRequestFileUpload',{attrs:{"disabled":_vm.enableUpload,"formType":"propertyChangeRequest","attachments":_vm.propertyChangeRequestData.attachments},on:{"upload-success":_vm.attachmentUploadHandler,"delete-attachment":_vm.handleAttachmentDelete}})],1):_vm._e(),_c('ModalMessage',{attrs:{"name":"removeDataModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Remove Data","description":"Would you like to remove the data from this section?","showClose":true,"buttons":[
              {
                text: `Confirm`,
                classList: [''],
                click: () => _vm.confirmModal()
              },
              {
                text: `Cancel`,
                classList: [''],
                click: () => _vm.$modal.hide('removeDataModal')
              }
            ]}})],1):_vm._e()])}),0)]),_c('div',{staticClass:"flex gap-2 w-full mt-8"},[_c('mortgage-company-picker-modal',{on:{"tableRowClicked":_vm.scrubMortgageData}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{attrs:{"for":""}},[_vm._v("List This Mortgage Company as "),_c('strong',{staticStyle:{"color":"red"}},[_vm._v("(*)")]),_vm._v(": ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{attrs:{"for":""}},[_vm._v("Bill Mortgage Company "),_c('strong',{staticStyle:{"color":"red"}},[_vm._v("(*)")]),_vm._v(" : ")])
}]

export { render, staticRenderFns }